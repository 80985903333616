@page {
  margin: 20px;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    display: block;
    margin-top: '100px';
    page-break-before: always;
  }
}
