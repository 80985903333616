.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.react-datepicker {
  border-radius: 8px;
  font-family: unset;
  font-size: 0.9rem;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 90px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  height: 45px;
}

.react-datepicker__navigation--previous {
  border-right-color: #130080;
}

.react-datepicker__day--today {
  background-color: #6b7982;
}

.react-datepicker__day-name {
  color: #fff;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  padding: 2px;
  font-size: 12px;
  border-radius: 2px;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #674dfe;
}

.react-datepicker__navigation--next {
  border-left-color: #130080;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #674dfe;
}

.react-datepicker__header {
  background: #6b7982;
}

.react-datepicker__header,
.react-datepicker__time-container {
  border-color: #e9e4ff;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
  color: #fff;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  margin: 0 1px 0 0;
  height: auto;
  padding: 7px 10px;
  background: #130080;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: #e9e4ff;
}

.react-datepicker__day:hover {
  background: rgba(107, 121, 130, 0.3);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: #6b7982;
  font-weight: normal;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background: #130080;
}

.react-datepicker__close-icon::after {
  background-color: unset;
  border-radius: unset;
  font-size: 1rem;
  font-weight: bold;
  color: #6b7982;
  padding: 1px 3px 3px 4px;
  border-radius: 50%;
  background: #e9e4ff;
}

.react-datepicker__close-icon::after:hover {
  color: hsl(0, 0%, 70%);
}
